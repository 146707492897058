// extracted by mini-css-extract-plugin
export var blush = "bs_dK";
export var blushIllustration = "bs_ds";
export var button = "bs_bn";
export var cardHeadline = "bs_dt";
export var cardRoot = "bs_dr";
export var cardSubtitle = "bs_dJ";
export var resourcesTileMobileVerticalContainer = "bs_dH";
export var rowTwo = "bs_dG";
export var rowtwosignal = "bs_dL";
export var wrapper = "bs_bl";