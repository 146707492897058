// extracted by mini-css-extract-plugin
export var actions = "br_bQ";
export var articleMultipleChoiceCTA = "br_dw";
export var body = "br_dy";
export var bodyContainer = "br_dx";
export var illustration = "br_dB";
export var selected = "br_dF";
export var subtitle = "br_dD";
export var textContent = "br_dC";
export var tile = "br_dz";
export var wrapper = "br_bl";